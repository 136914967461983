import styled from '@emotion/styled';

type TagProps = {
  size: 'xs' | 'lg';
};

export const StyledTag = styled.div<TagProps>`
  border-width: 1px;
  display: inline-flex;
  height: ${({ size }) => (size === 'lg' ? '2rem' : '1.5rem')};
`;
