'use client';

import { PeopleTwoTone } from '@mui/icons-material';
import { useIsFeatureActive } from '@sortlist-frontend/feature-flags';
import { TFunction, Trans } from '@sortlist-frontend/translation/server-only';
import { FC } from 'react';

import { highlightStyle } from '../../utils';
import { getTeamSizeInterval } from './utils';

type Props = {
  teamSize: number;
  appRouter: boolean;
  t: TFunction;
};

export const TeamSizeDisplay: FC<Props> = (props) => {
  const { teamSize, t, appRouter } = props;
  const teamSizeInterval = getTeamSizeInterval(teamSize);

  const { variant } = useIsFeatureActive('reduce-display-information');

  return (
    <div className="text-secondary-500 small layout-row layout-align-start-center agency-info-cell">
      <PeopleTwoTone
        className="text-secondary-500 p-4 bg-secondary-200 mr-16 rounded-sm"
        style={{ fontSize: '1.5rem' }}
      />
      {variant === 'variation-removal' ? (
        <span className={highlightStyle}>{teamSizeInterval}</span>
      ) : (
        <Trans i18nKey={'longtail:agencyCard.teamSize'} t={t} appRouter={appRouter}>
          <span className={highlightStyle}>{{ teamSize: teamSizeInterval }}</span> members
        </Trans>
      )}
    </div>
  );
};
