import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { FC } from 'react';

type SnackbarContentProps = {
  addedToShortlist: boolean;
  setMenuOpened: (open: boolean) => void;
};

export const SnackbarContent: FC<SnackbarContentProps> = (props) => {
  const { setMenuOpened, addedToShortlist } = props;
  const { t } = useTranslation(['common']);
  return (
    <>
      {addedToShortlist ? (
        <p className="text-secondary-100">{t('common:comparator.tooltip.agencyAddedToYourShortlist')}</p>
      ) : (
        <p className="text-secondary-100">{t('common:comparator.tooltip.agencyRemovedFromYourShortlist')}</p>
      )}
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        className="pl-8 small text-neutral-100 underline"
        href="#"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setMenuOpened(true);
        }}>
        {t('common:comparator.tooltip.seeTheList')}
      </a>
    </>
  );
};
