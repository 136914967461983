import styled from '@emotion/styled';
import { COLORS } from '@sortlist-frontend/design-system';

export const ButtonContainer = styled.div`
  .btn {
    svg {
      color: ${COLORS.SECONDARY_500};
    }
    &.active {
      svg {
        color: ${COLORS.DANGER_500};
      }
    }
  }
`;
