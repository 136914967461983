import { isNonEmptyString } from '@sortlist-frontend/utils';

import german from '_public/static/languages/de.json';
import english from '_public/static/languages/en.json';
import spanish from '_public/static/languages/es.json';
import french from '_public/static/languages/fr.json';
import italian from '_public/static/languages/it.json';
import dutch from '_public/static/languages/nl.json';

import { language, locale } from './languages.types';

const languagesMapping = {
  de: german,
  en: english,
  es: spanish,
  fr: french,
  it: italian,
  nl: dutch,
} as const;

type SupportedLanguageLocale = keyof typeof languagesMapping;

type languageTranslatorMethod = (language: language) => string | undefined;

export const isSupportedLanguageLocale = (locale: unknown): locale is SupportedLanguageLocale => {
  return isNonEmptyString(locale) && locale in languagesMapping;
};

export const getLanguageTranslator = (locale: locale): languageTranslatorMethod => {
  const languages = languagesMapping[locale];

  return (locale) => languages[locale];
};
