'use client';

/* eslint-disable react/jsx-fragments */
import { LanguageTwoTone } from '@mui/icons-material';
import { Tooltip } from '@sortlist-frontend/design-system/ssr';
import { useIsFeatureActive } from '@sortlist-frontend/feature-flags';
import { TFunction, Trans } from '@sortlist-frontend/translation/server-only';
import { Fragment } from 'react';

import { Page } from '_types/public-api';

import { highlightStyle } from '../../utils';
import { getLanguages } from './utils';

type Props = {
  metadata?: Page['data']['attributes']['metadata'];
  languages: string[];
  locale: string;
  mainLocale: string;
  appRouter: boolean;
  t: TFunction;
};

export const LanguagesDisplay = (props: Props) => {
  const { languages, t, appRouter, locale, mainLocale } = props;
  const languagesMapped = getLanguages(languages, locale);
  const languagesToDisplay: (string | undefined)[] = [];
  languagesToDisplay.push(
    languagesMapped.find((language) => language.languageCode === mainLocale)?.name ?? languagesMapped[0].name,
  );

  if (languagesMapped.length > 1) {
    languagesToDisplay.push(languagesMapped.find((lang) => !languagesToDisplay.includes(lang.name))?.name);
  }
  languagesMapped.forEach((lang) => {
    if (languagesToDisplay.includes(lang.name)) return;

    languagesToDisplay.push(lang.name);
  });

  const Languages = () => {
    if (languagesMapped.length > 2) {
      return (
        <Fragment>
          <Trans
            i18nKey={'longtail:agencyCard.languages_other_v2'}
            t={t}
            appRouter={appRouter}
            count={languagesToDisplay.length - 2}>
            Speaks
            <span className={`${highlightStyle} mr-4`}>
              {{ languages: languagesToDisplay.slice(0, 2).join(', ') }}
            </span>{' '}
          </Trans>
          <Tooltip title={languagesToDisplay.slice(2).join(', ')}>
            <span>
              (<span className="underline">+{languagesToDisplay.length - 2}</span>)
            </span>
          </Tooltip>
        </Fragment>
      );
    }

    return (
      <Trans i18nKey={'longtail:agencyCard.languages'} t={t} appRouter={appRouter}>
        Speaks
        <span className={highlightStyle}>{{ languages: languagesToDisplay.slice(0, 2).join(', ') }}</span>
      </Trans>
    );
  };

  const LanguagesForReducedDisplay = () => {
    if (languagesMapped.length > 2) {
      return (
        <span>
          <span className={highlightStyle}>{languagesToDisplay.slice(0, 2).join(', ')}</span>
          &nbsp;
          <Tooltip title={languagesToDisplay.slice(2).join(', ')}>
            <span>
              (<span className="underline">+{languagesToDisplay.length - 2}</span>)
            </span>
          </Tooltip>
        </span>
      );
    }

    return <span className={highlightStyle}>{languagesToDisplay.slice(0, 2).join(', ')}</span>;
  };

  const { variant } = useIsFeatureActive('reduce-display-information');

  return (
    <div className="text-secondary-500 agency-info-cell agency-info-hide-card small layout-row layout-align-start-center">
      <LanguageTwoTone
        className="text-secondary-500 p-4 bg-secondary-200 mr-16 rounded-sm"
        style={{ fontSize: '1.5rem' }}
      />
      {variant === 'variation-removal' ? <LanguagesForReducedDisplay /> : <Languages />}
    </div>
  );
};
