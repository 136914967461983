'use client';

import { DesignServicesTwoTone } from '@mui/icons-material';
import { useIsFeatureActive } from '@sortlist-frontend/feature-flags';
import { TFunction, Trans } from '@sortlist-frontend/translation/server-only';
import { FC } from 'react';

import { highlightStyle } from '../../utils';

type Props = {
  worksCount: number;
  serviceName?: string;
  t: TFunction;
  appRouter: boolean;
};

export const WorksDisplay: FC<Props> = (props) => {
  const { worksCount, serviceName, t, appRouter } = props;

  const { variant } = useIsFeatureActive('reduce-display-information');

  const WorksForReducedDisplay = () => {
    return (
      <span className={highlightStyle}>{t('longtail:agencyCard.worksNoService_1', { count: worksCount ?? 0 })}</span>
    );
  };

  const Works = () => {
    if (serviceName != null) {
      return (
        <Trans i18nKey={'longtail:agencyCard.works_1'} t={t} count={worksCount} appRouter={appRouter}>
          <span className={highlightStyle}>{{ count: worksCount }} works</span>
          in
          <span className={highlightStyle}>{{ category: serviceName }}</span>
        </Trans>
      );
    }
    return (
      <span className={highlightStyle}>{t('longtail:agencyCard.worksNoService_1', { count: worksCount || 0 })}</span>
    );
  };

  const NoWork = () => {
    if (serviceName != null) {
      return (
        <Trans i18nKey={'longtail:agencyCard.noWorks'} t={t} appRouter={appRouter}>
          <span className={highlightStyle}>Looking for work</span> in&nbsp;
          <span className={highlightStyle}>{{ category: serviceName }}</span>
        </Trans>
      );
    }
    return <span className={highlightStyle}>{t('longtail:agencyCard.worksNoService_zero_1')}</span>;
  };

  return (
    <div className="small layout-row layout-align-start-center agency-info-cell">
      <DesignServicesTwoTone
        className="text-secondary-500 mr-16 p-4 bg-secondary-200 rounded-sm"
        style={{ fontSize: '1.5rem' }}
      />
      <span className="text-secondary-500">
        {worksCount === 0 ? <NoWork /> : variant === 'variation-removal' ? <WorksForReducedDisplay /> : <Works />}
      </span>
    </div>
  );
};
