import { Tooltip } from '@sortlist-frontend/design-system';
import { cloneElement, ReactElement } from 'react';

interface BadgeWithTooltipProps {
  title: string;
  description: string;
  icon: ReactElement;
}

export const BadgeWithTooltip = ({ title, description, icon }: BadgeWithTooltipProps) => {
  return (
    <Tooltip className="text-center" arrow={true} placement="top" title={title} suppressHydrationWarning>
      <div className="layout-row layout-align-center-center px-8 py-4 border border-secondary-300 rounded-sm">
        {cloneElement(icon, { className: 'text-secondary-500', style: { fontSize: '0.875rem' } })}
        <span className="ml-4 small text-truncate flex" suppressHydrationWarning>
          {description}
        </span>
      </div>
    </Tooltip>
  );
};
