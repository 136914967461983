'use client';

import { cx } from '@emotion/css';
import { EmojiEventsTwoTone, ThumbUpOffAltTwoTone, WorkspacePremiumTwoTone } from '@mui/icons-material';
import { ClampLines } from '@sortlist-frontend/design-system/ssr';
import { useIsFeatureActive } from '@sortlist-frontend/feature-flags';
import { TFunction } from '@sortlist-frontend/translation/server-only';
import dynamic from 'next/dynamic';

import { Expertise } from '_core/repos/expertises.repo';
import { Agency, PagesAgency } from '_types/public-api';

import { BadgeWithTooltip } from './BadgeWithTooltip';
import { getIsTopAwarded, getRecommendedReviews } from './utils';

const FeatureFlagComponents = dynamic(
  () =>
    import('_features/longtail/sections/AgenciesPanel/AgencyCard/components/FeatureFlagComponents').then(
      (mod) => mod.FeatureFlagComponents,
    ),
  { ssr: false },
);

type Props = {
  agency: Agency['attributes'];
  t: TFunction;
  data: PagesAgency;
  expertises?: Expertise[];
  paid: boolean;
  pageExpertiseId?: number;
};

export const Badges = (props: Props) => {
  const { agency, t, data, expertises, pageExpertiseId } = props;
  const { awards, description, reviews } = agency;

  const { description: serviceDescription, description_translated: translatedDescription } =
    data.attributes.agency_card_metadata ?? {};

  const { isActive: isExperienceDonutActive } = useIsFeatureActive('experience-level-donut');

  const { isActive: isTranslatedDescriptionActive } = useIsFeatureActive('cards-translated-description');

  const { variant: cardsExpertiseDescriptionVariant } = useIsFeatureActive('cards-expertise-description');

  const getDescription = (): string => {
    if (isTranslatedDescriptionActive) {
      return translatedDescription ?? description;
    }

    if (cardsExpertiseDescriptionVariant === 'control') {
      return description;
    }

    return serviceDescription ?? description;
  };

  const isTopAwarded = getIsTopAwarded(awards);

  const recommendedReview = getRecommendedReviews(reviews);
  const totalRecommendedReview = recommendedReview.length;

  const isRecommended = totalRecommendedReview > 1 && totalRecommendedReview < 5;
  const isHighlyRecommended = totalRecommendedReview >= 5;

  return (
    <div
      className={cx(
        isExperienceDonutActive ? 'layout-row layout-align-space-between-stretch' : 'layout-column',
        'width-100',
      )}>
      <div className="layout-column">
        {!isRecommended && !isHighlyRecommended && !isTopAwarded ? null : (
          <div className={'agency-main agency-badges width-100 layout-wrap layout-row gap-x-8 gap-y-8'}>
            {isRecommended && (
              <BadgeWithTooltip
                icon={<ThumbUpOffAltTwoTone />}
                title={t('longtail:agencyCard.recommendedTooltip_v2')}
                description={t('longtail:agencyCard.recommended')}
              />
            )}

            {isHighlyRecommended && (
              <BadgeWithTooltip
                icon={<WorkspacePremiumTwoTone />}
                title={t('longtail:agencyCard.highlyRecommendedTooltip_v2')}
                description={t('longtail:agencyCard.highlyRecommended')}
              />
            )}

            {isTopAwarded && (
              <BadgeWithTooltip
                icon={<EmojiEventsTwoTone />}
                title={t('longtail:agencyCard.awardWinnerTooltip_v2')}
                description={t('longtail:agencyCard.topAwarded')}
              />
            )}
          </div>
        )}

        <div className="agency-main pt-0 agency-info-hide-card">
          {isTranslatedDescriptionActive && translatedDescription != null ? (
            <span className="mr-2 text-primary-500 small">{t('longtail:agencyCard.translatedDescription')}</span>
          ) : null}

          <ClampLines
            className="small text-secondary-500 lh-2 hide show-gt-xs"
            seeMoreLessClassName="text-nowrap small"
            lines={isExperienceDonutActive ? 4 : 2}
            sanitizeCollapsedText={true}
            text={getDescription()}
            showMoreLessText={false}
            seeLessText={t('common:clampLines.seeLess')}
            seeMoreText={t('common:clampLines.seeMore')}
          />
        </div>
      </div>
      <FeatureFlagComponents data={data} t={t} expertises={expertises} pageExpertiseId={pageExpertiseId} />
    </div>
  );
};
