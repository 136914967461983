export const normalizeRating = (rating?: number | null): number => {
  // use != to ensure that we also check against null if for whatever reason that happens
  if (rating != null) {
    /**
     * ratings mapping used for the calculation:
     * 0 => 1 star
     * 0.25 => 2 stars
     * 0.5 => 3 stars
     * 0.75 => 4 stars
     * 1 => 5 stars
     */
    return Math.round((1 + rating * 4) * 100) / 100;
  }
  return 0;
};
/**
 * Method to compute the rating number between 1 and 5
 * @param rate decimal number (0.0 - 1.0)
 * @returns Rating number between 1 and 5
 */
export const getFormattedRating = (rate?: number | null) => {
  const rating = normalizeRating(rate ?? 0);

  if (rating === Math.ceil(rating)) {
    return rating;
  } else {
    const roundedRating = Number((Math.ceil(rating * 100) / 100).toFixed(2));
    if (roundedRating === Math.round(roundedRating)) {
      return Math.ceil(roundedRating);
    }
    return roundedRating;
  }
};

export const highlightStyle = 'text-secondary-900 medium';
