import { Snackbar, SnackbarProps } from '@sortlist-frontend/design-system';
import { useWindowMessage, WindowMessageParams } from '@sortlist-frontend/utils';
import { useState } from 'react';

import { useComparatorMessage } from '_components/Comparator/Menu/ComparatorSidebarEmbed';
import { SnackbarContent } from '_components/Comparator/SnackbarContent';

export const OPEN_SNACKBAR = 'open-snackbar';
export const CLOSE_SNACKBAR = 'close-snackbar';

type Action = typeof OPEN_SNACKBAR | typeof CLOSE_SNACKBAR;
type Data = {
  message?: string;
  addedToShortlist?: boolean;
};

export const useSnackbarMessage = (params?: WindowMessageParams<Action, Data>) =>
  useWindowMessage<Action, Data>(params);

export const SnackbarProviderLight = () => {
  const [snackBarOptions, setSnackBarOptions] = useState<SnackbarProps | null>(null);

  const { sendMessage: openCompartorSidebar } = useComparatorMessage({ sendToIframeId: 'directory-actions' });
  const { sendMessage } = useSnackbarMessage({
    onReceiveMessage: (action, data) => {
      if (action === OPEN_SNACKBAR) {
        setSnackBarOptions({
          message: data?.message ?? (
            <SnackbarContent
              setMenuOpened={(value) => {
                openCompartorSidebar(value === true ? 'open-comparator' : 'close-comparator');
              }}
              addedToShortlist={!!data?.addedToShortlist}
            />
          ),
        });
      }
      if (action === CLOSE_SNACKBAR) {
        setSnackBarOptions(null);
      }
    },
  });

  return (
    <Snackbar
      open={snackBarOptions != null}
      onClose={() => {
        setSnackBarOptions(null);
        sendMessage(CLOSE_SNACKBAR);
      }}
      {...snackBarOptions}
    />
  );
};
