'use client';

/* eslint-disable react/jsx-fragments */
import { BusinessTwoTone } from '@mui/icons-material';
import { Tooltip } from '@sortlist-frontend/design-system/ssr';
import { useIsFeatureActive } from '@sortlist-frontend/feature-flags';
import { TFunction, Trans } from '@sortlist-frontend/translation/server-only';
import { FC, Fragment } from 'react';

import { highlightStyle } from '../../utils';

type Props = {
  sectors: string[];
  appRouter: boolean;
  t: TFunction;
};

export const SectorsDisplay: FC<Props> = (props) => {
  const { sectors, t, appRouter } = props;

  const { variant } = useIsFeatureActive('reduce-display-information');

  const SectorsForReducedDisplay = () => {
    return (
      <span>
        <span className={highlightStyle}>{sectors.slice(0, 1).join(', ')}</span>
        {sectors.length > 1 && (
          <Fragment>
            &nbsp;
            <Tooltip arrow={true} title={sectors.slice(1).join(', ')}>
              <span>
                (<span className="underline">+{sectors.length - 1}</span>)
              </span>
            </Tooltip>
          </Fragment>
        )}
      </span>
    );
  };

  const SectorForDisplay = () => (variant === 'variation-removal' ? <SectorsForReducedDisplay /> : <Sectors />);

  const Sectors = () => {
    return (
      <span>
        <Trans i18nKey={'longtail:agencyCard.sectors_v2'} t={t} appRouter={appRouter}>
          Works in
          <span className={highlightStyle}>{{ sector: sectors?.slice(0, 1).join(', ') }}</span>
        </Trans>
        {sectors.length > 1 ? (
          <Fragment>
            &nbsp;
            <Tooltip arrow={true} title={sectors.slice(1).join(', ')}>
              <span>
                (<span className="underline bold">+{sectors.length - 1}</span>)
              </span>
            </Tooltip>
          </Fragment>
        ) : null}
      </span>
    );
  };

  const NoSector = () => {
    return (
      <Trans i18nKey={'longtail:agencyCard.noSector_v2'} t={t} appRouter={appRouter}>
        Works in <span className={highlightStyle}>multiple industries</span>
      </Trans>
    );
  };

  return (
    <div className="text-secondary-500 agency-info-cell agency-info-hide-card small layout-row layout-align-start-center">
      <BusinessTwoTone
        className="text-secondary-500 p-4 bg-secondary-200 mr-16 rounded-sm"
        style={{ fontSize: '1.5rem' }}
      />
      {sectors.length > 0 ? <SectorForDisplay /> : <NoSector />}
    </div>
  );
};
