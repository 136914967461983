'use client';

import { EuroSymbolRounded } from '@mui/icons-material';
import { useIsFeatureActive } from '@sortlist-frontend/feature-flags';
import { TFunction, Trans } from '@sortlist-frontend/translation/server-only';
import { formatNumber } from '@sortlist-frontend/utils';
import { FC } from 'react';

import { highlightStyle } from '../../utils';

type Props = {
  budget: number[] | null;
  serviceName?: string;
  appRouter: boolean;
  t: TFunction;
  locale: string;
};

export const BudgetDisplay: FC<Props> = (props) => {
  const { budget, serviceName, t, appRouter, locale } = props;

  const { variant } = useIsFeatureActive('reduce-display-information');
  const noBudget = budget == null || budget.length === 0;

  const formattedBudget = formatNumber({
    value: budget?.[0] ?? 0,
    locale,
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
  });

  const BudgetForReducedDisplay = () => {
    return <span className={highlightStyle}>{formattedBudget}+</span>;
  };

  const BudgetForDisplay = () => {
    return variant === 'variation-removal' ? <BudgetForReducedDisplay /> : <Budget />;
  };

  const Budget = () => {
    if (serviceName != null) {
      return (
        <Trans i18nKey={'longtail:agencyCard.budget_v2'} t={t} appRouter={appRouter}>
          From
          <span className={highlightStyle}>{{ budget: formattedBudget }}</span>
          for
          <span className={highlightStyle}>{{ category: serviceName }}</span>
        </Trans>
      );
    }

    return (
      <Trans i18nKey={'longtail:agencyCard.budgetNoService_v2'} t={t} appRouter={appRouter}>
        From
        <span className={highlightStyle}>{{ budget: formattedBudget }}</span>
      </Trans>
    );
  };

  const NoBudget = () => {
    return (
      <Trans i18nKey={'longtail:agencyCard.budget_zero_v2'} t={t} appRouter={appRouter}>
        Budget <span className={highlightStyle}>on request</span>
      </Trans>
    );
  };

  return (
    <div className="text-secondary-500 agency-info-cell small layout-row layout-align-start-center">
      <EuroSymbolRounded
        className="text-secondary-500 p-4 bg-secondary-200 mr-16 rounded-sm"
        style={{ fontSize: '1.5rem' }}
      />
      {noBudget ? <NoBudget /> : <BudgetForDisplay />}
    </div>
  );
};
