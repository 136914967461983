export const getTeamSizeInterval = (teamSize: number) => {
  if (teamSize < 10) return '1-10';
  if (teamSize < 50) return '11-50';
  if (teamSize < 200) return '51-200';
  if (teamSize < 500) return '201-500';
  if (teamSize < 1000) return '501-1000';
  if (teamSize < 5000) return '1001-5000';
  if (teamSize < 10000) return '5001-10000';
  return '10001+';
};
