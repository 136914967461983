import { Trans as TransWithoutContext } from 'react-i18next/TransWithoutContext';

import { TFunction } from '.';
import { TransWithContext } from '../ssr/TransWithContext';

type Props = {
  i18nKey: string;
  t: TFunction;
  count?: number;
  appRouter: boolean;
  children: React.ReactNode | Record<string, unknown>;
};

export const Trans = (props: Props) => {
  const { i18nKey, t, count, children, appRouter } = props;

  return appRouter === true ? (
    <span>
      <TransWithoutContext i18nKey={i18nKey as any} t={t} count={count}>
        {children}
      </TransWithoutContext>
    </span>
  ) : (
    <TransWithContext i18nKey={i18nKey} count={count}>
      {children}
    </TransWithContext>
  );
};
