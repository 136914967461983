import { Agency } from '_types/public-api';

export const getRecommendedReviews = (reviews: Agency['attributes']['reviews']) => {
  return reviews.filter((review) => isRecommendedWithinLast18mnths(review));
};

const isRecommendedWithinLast18mnths = (review: Agency['attributes']['reviews'][0]) => {
  if (!review.recommended) return false;
  const cutOffDate = new Date().setMonth(new Date().getMonth() - 18);
  return new Date(review.created_at) > new Date(cutOffDate);
};

export const MINIMUM_TOTAL_AWARDS = 3;

export const getIsTopAwarded = (awards: Agency['attributes']['awards']) => (awards?.length ?? 0) > MINIMUM_TOTAL_AWARDS;
