import { language, locale } from '_core/utils/languages/languages.types';
import { getLanguageTranslator } from '_core/utils/languages/languages.util';

export const getLanguages = (languages: string[], locale: string) => {
  const getLanguageName = getLanguageTranslator(locale as unknown as locale);

  return languages
    .map((languageCode) => ({
      languageCode,
      name: getLanguageName(languageCode as unknown as language),
    }))
    .filter(({ name }) => !!name)
    .sort(({ name: nameA }, { name: nameB }) => (nameA && nameB ? nameA.localeCompare(nameB) : 0));
};
