'use client';

import { Trans } from '.';

type Props = {
  count?: number;
  i18nKey: string;
  children: React.ReactNode | Record<string, unknown>;
};

export const TransWithContext = (props: Props) => {
  const { count, i18nKey } = props;

  return (
    <Trans i18nKey={i18nKey as any} count={count}>
      {props.children}
    </Trans>
  );
};
