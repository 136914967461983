import { TagColor, TagVariant } from './types';

const getPlainBackgroundColorClass = (color: TagColor) => {
  return color === 'primary' ? `bg-primary-500` : `bg-${color}-300`;
};

const getLightBackgroundColorClass = (color: TagColor) => {
  return `bg-${color}-200`;
};

export const getBackgroundColorClass = (color: TagColor, variant: TagVariant) => {
  switch (variant) {
    case 'plain':
      return getPlainBackgroundColorClass(color);
    case 'light':
      return getLightBackgroundColorClass(color);
    default:
      return;
  }
};

const getPlainTextColorClass = (color: TagColor) => {
  switch (color) {
    case 'primary':
      return 'text-neutral-100';
    case 'secondary':
      return 'text-secondary-900';
    default:
      return `text-${color}-700`;
  }
};

const getLightTextColorClass = (color: TagColor) => {
  return color === 'primary' ? 'text-primary-500' : `text-${color}-700`;
};

const getOutlinedTextColorClass = (color: TagColor) => {
  return color === 'primary' ? 'text-primary-500' : `text-${color}-700`;
};

const getDashedTextColorClass = (color: TagColor) => {
  return color === 'primary' ? 'text-primary-500' : `text-${color}-700`;
};

export const getTextColorClass = (color: TagColor, variant: TagVariant) => {
  switch (variant) {
    case 'plain':
      return getPlainTextColorClass(color);
    case 'light':
      return getLightTextColorClass(color);
    case 'outlined':
      return getOutlinedTextColorClass(color);
    case 'dashed':
      return getDashedTextColorClass(color);
  }
};

const getPlainBorderColorClass = (color: TagColor) => {
  return color === 'primary' ? `border-primary-500` : `border-${color}-300`;
};

const getLightBorderColorClass = (color: TagColor) => {
  return `border-${color}-200`;
};

const getOutlinedBorderColorClass = (color: TagColor) => {
  switch (color) {
    case 'primary':
      return 'border-primary-300';
    case 'secondary':
      return 'border-secondary-300';
    default:
      return `border-${color}-700`;
  }
};

const getDashedBorderColorClass = (color: TagColor) => {
  switch (color) {
    case 'primary':
      return 'border-primary-500';
    case 'secondary':
      return 'border-secondary-500';
    default:
      return `border-${color}-700`;
  }
};

export const getBorderColorClass = (color: TagColor, variant: TagVariant) => {
  switch (variant) {
    case 'plain':
      return getPlainBorderColorClass(color);
    case 'light':
      return getLightBorderColorClass(color);
    case 'outlined':
      return getOutlinedBorderColorClass(color);
    case 'dashed':
      return getDashedBorderColorClass(color);
  }
};
