export const getLocalisedLocation = (offices: string[], pageLocation?: string) => {
  if (pageLocation == null) return offices[0];

  const cleanPageLocation = pageLocation?.toLowerCase().replace(' ', '');
  let officeInCountry: string | null = null,
    officeInCity = null;

  offices.forEach((office) => {
    const [city, country] = office.toLowerCase().replace(' ', '').split(',');
    if (cleanPageLocation.includes(city)) {
      officeInCity = office;
    }
    if (officeInCountry == null && cleanPageLocation.includes(country)) {
      officeInCountry = office;
    }
  });

  return officeInCity ?? officeInCountry ?? offices[0];
};
