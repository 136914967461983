/* eslint-disable react/jsx-filename-extension */
'use client';

import { TrackingParamsCpc } from '@sortlist-frontend/tracking';
import { useLocalStorage } from '@sortlist-frontend/utils';
import { useEffect } from 'react';

import { DomainInfo } from '_backend/integration/domain-info';
import { NavigationData } from '_components/layout/Layout';
import { getUrlHelper } from '_components/layout/PrimaryNav/DesktopNav/utils';
import { useTracker } from '_core/hooks/use-tracker';
import { useTrackWithoutContext } from '_core/repos/track.repo';
import { getSourceObject } from '_core/tracking/entry-url-params-storer';
import { Agency, Page, PagesAgency } from '_types/public-api';

type ClientActionsProps = {
  page: Page;
  agency: Agency;
  data: PagesAgency;
  keyInArray: number;
  paid?: boolean;
  navigationData: NavigationData;
};

export const ClientActions = (props: ClientActionsProps) => {
  const { page, data, keyInArray, paid, navigationData } = props;

  const { origin, locale, resolvedUrl } = navigationData;
  const domainInfo = DomainInfo.getFromOrigin(origin);
  const getUrl = getUrlHelper(domainInfo, locale);

  const agency = props?.agency?.attributes;

  const isSeaPage = page.data.attributes.frontend_type === 'google_ads_visibility';

  const [, setValue] = useLocalStorage('directBriefingDataKey', {});

  const { mutateAsync: internalTrack } = useTrackWithoutContext(origin, `${origin}${resolvedUrl}`);
  const { trackUntyped, ready } = useTracker();

  /**
   * This will set data that we use later when opening a directBriefing on the agency profile page
   */
  const setDirectBriefingDataKey = () => {
    const { location: dbLocation, expertise } = page?.data?.attributes.briefing_options || {};
    const location = { ...dbLocation, placeId: dbLocation?.place_id };

    setValue(JSON.stringify({ location, expertise }));
  };

  const trackAndOpenAgencyProfileOnClick = (cta: string, openWebsite = false) => {
    const { absoluteEntryUrl, absoluteEntryDate, sessionEntryUrl } = getSourceObject();
    const eventName = openWebsite ? 'websiteOpened' : 'agencyCardClicked';
    // call internal tracking
    const trackData: TrackingParamsCpc = {
      name: 'agencyCardClicked',
      url: location.href,
      agency_external_id: agency.external_id,
      position: paid ? keyInArray + 1 : data?.attributes.position + 1,
      result_type: data?.attributes.section.toLowerCase(),
      cta,
    };

    Promise.all([
      internalTrack(trackData).catch(() => {}),
      trackUntyped(
        eventName,
        {
          agencyName: agency.name,
          agencyPlan: agency?.visibility_plan,
          agencySlug: agency.slug,
          position: paid ? keyInArray + 1 : data?.attributes.position + 1,
          result_type: data?.attributes.section,
          cta: cta,
          page: 'longtail',
          absoluteEntryUrl,
          absoluteEntryDate,
          sessionEntryUrl,
        },
        { synchronous: true },
      ),
    ]);

    if (openWebsite) {
      window.open(
        `${agency.website_url}?utm_source=sortlist&utm_medium=featured&utm_campaign=${encodeURIComponent(sessionEntryUrl ?? window.location.href)}`,
        '_blank',
      );
    } else {
      window.open(getUrl('agency.profile', { slug: agency.slug }), '_blank');
    }
  };

  useEffect(() => {
    const type = paid ? 'featured' : 'normal';

    const card = document.getElementById(`agency-card-${agency.slug}-${type}`);
    const cta = document.getElementById(`agency-card-cta-${agency.slug}-${type}`);

    const isWebsiteCta = paid != null && agency.website_url != null;

    const handleClick = (e: MouseEvent, isWebsiteCta: boolean) => {
      e.stopPropagation();
      setDirectBriefingDataKey();
      trackAndOpenAgencyProfileOnClick('card', isWebsiteCta);
    };

    card?.addEventListener('click', (e) => handleClick(e, false));
    cta?.addEventListener('click', (e) => handleClick(e, isWebsiteCta));

    return () => {
      card?.removeEventListener('click', (e) => handleClick(e, false));
      cta?.removeEventListener('click', (e) => handleClick(e, isWebsiteCta));
    };
  }, []);

  return null;
};
