import { cx } from '@emotion/css';
import { cloneElement, ReactElement, ReactNode } from 'react';

import * as S from './styles';
import { TagColor, TagSize, TagVariant } from './types';
import { getBackgroundColorClass, getBorderColorClass, getTextColorClass } from './utils';

type TagProps = {
  label: ReactNode;
  size: TagSize;
  color: TagColor;
  type: 'rounded' | 'square';
  variant: TagVariant;
  iconLeft?: ReactElement;
  iconRight?: ReactElement;
  elementLeft?: ReactElement;
  elementRight?: ReactElement;
  className?: string;
  dataTestId?: string;
};

export const Tag = (props: TagProps) => {
  const { label, size, color, variant, type, iconLeft, iconRight, className, dataTestId } = props;

  const backgroundColorClass = getBackgroundColorClass(color, variant);
  const textColorClass = getTextColorClass(color, variant);
  const borderColorClass = getBorderColorClass(color, variant);
  const borderTypeClass = variant === 'dashed' ? 'border-dashed' : 'border';
  const borderRadiusClass = type === 'rounded' ? 'rounded-xxl' : 'rounded-sm';
  const iconSize = size === 'lg' ? 'p' : 'small';

  return (
    <S.StyledTag
      size={size}
      className={cx(
        className,
        'px-8 py-4 layout-row layout-align-center-center',
        size === 'lg' ? 'p' : 'small',
        backgroundColorClass,
        textColorClass,
        borderTypeClass,
        borderColorClass,
        borderRadiusClass,
      )}
      data-testid={dataTestId ?? 'tag-component'}>
      {iconLeft != null &&
        cloneElement(iconLeft, {
          className: `${iconSize} ${iconLeft.props.className}`,
        })}
      <span className="px-4" style={{ marginTop: -1 }}>
        {label}
      </span>
      {iconRight != null &&
        cloneElement(iconRight, {
          className: `${iconSize} ${iconRight.props.className}`,
        })}
    </S.StyledTag>
  );
};
