'use client';

import { LocationOnTwoTone } from '@mui/icons-material';
import { Tooltip } from '@sortlist-frontend/design-system/ssr';
import { useIsFeatureActive } from '@sortlist-frontend/feature-flags';
import { TFunction, Trans } from '@sortlist-frontend/translation/server-only';
import { FC, Fragment } from 'react';

import { Page } from '_types/public-api';

import { highlightStyle } from '../../utils';
import { getLocalisedLocation } from './utils';

type Props = {
  metadata?: Page['data']['attributes']['metadata'];
  locationNames: string[];
  appRouter: boolean;
  t: TFunction;
};

export const LocationDisplay: FC<Props> = (props) => {
  const { locationNames: agencyOffices, t, appRouter } = props;
  const { default_address: locationName } = props.metadata ?? {};

  const location = getLocalisedLocation(agencyOffices, locationName);

  const { variant } = useIsFeatureActive('reduce-display-information');

  const LocationForReducedDisplay = () => {
    return (
      <span>
        <span className={highlightStyle}>{location}</span>
        {agencyOffices.length > 1 && (
          <Fragment>
            &nbsp;
            <Tooltip
              arrow={true}
              title={
                <ul className="mx-16 my-8 p-0">
                  {agencyOffices?.slice(1).map((location) => (
                    <li key={location} className="p-4">
                      {location}
                    </li>
                  ))}
                </ul>
              }>
              <span>
                (<span className="underline">+{agencyOffices.length - 1}</span>)
              </span>
            </Tooltip>
          </Fragment>
        )}
      </span>
    );
  };

  const LocationForDisplay = () => (variant === 'variation-removal' ? <LocationForReducedDisplay /> : <Location />);

  const Location = () => {
    return (
      <span>
        <Trans i18nKey={'longtail:agencyCard.located_v2'} t={t} appRouter={appRouter}>
          Located in <span className={highlightStyle}>{{ location }}</span>
        </Trans>
        {agencyOffices.length > 1 ? (
          <Fragment>
            &nbsp;
            <Tooltip
              arrow={true}
              title={
                <ul className="mx-16 my-8 p-0">
                  {agencyOffices?.slice(1).map((location) => (
                    <li key={location} className="p-4">
                      {location}
                    </li>
                  ))}
                </ul>
              }>
              <span>
                (<span className="underline">+{agencyOffices.length - 1}</span>)
              </span>
            </Tooltip>
          </Fragment>
        ) : null}
      </span>
    );
  };

  const NoLocation = () => <span>{t('longtail:agencyCard.unknownLocation')}</span>;

  return (
    <div className="text-secondary-500 agency-info-cell small layout-row layout-align-start-center">
      <LocationOnTwoTone
        className="text-secondary-500 p-4 bg-secondary-200 mr-16 rounded-sm"
        style={{ fontSize: '1.5rem' }}
      />
      {location != null ? <LocationForDisplay /> : <NoLocation />}
    </div>
  );
};
